import {useEffect} from 'react'
import * as Fathom from "fathom-client";
import { useLocation } from "react-router-dom";

const FathomComponent = () => {
  const location = useLocation();

  useEffect(() => {
    Fathom.trackPageview();
  }, [location]);

  return null;
};

export default FathomComponent;
