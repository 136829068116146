import React from "react";
import Fathom from "./../components/Fathom"

function IndexPageSimple(props) {
  return (
    <>
      <head>
        <meta charSet="utf-8" />
        <title>Nicer.email | Instantly improve your emails using ChatGPT.</title>
        <meta
          name="description"
          content="The easiest way to write great emails. With one click, ChatGPT makes your email polished, polite, and professional. A browser extension for Gmail. Works in Chrome, Edge, and Brave."
        />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/mvp.css@1.12.0/mvp.min.css" />
        <style
          dangerouslySetInnerHTML={{
            __html: `
     html, body {
       height: 100%;
       font-family: system-ui, sans-serif;
     }
     #root {
       padding: 0 1em 1em;
       display: flex;
       flex-direction: column;
       align-items: center;
       height: 100%;
     }
     .content {
       display: flex;
       flex: 1 0 auto;
       padding: 20px;
       flex-direction: column;
       align-items: center;
     }
     @media screen and (min-width: 885px) {
       .main-image { width: 885px; height: 564px; }
     }
     .main-image img { width: 100%; }
     footer {
       flex-shrink: 0;
       padding: 20px;
     }
     p { max-width: 600px; text-align: center;}`
          }}
        />
      </head>
      <Fathom />
      <>
        <div className="content">
          <h1>One click for great emails. 🙂</h1>
          <p>Nicer.email is a Gmail extension that uses AI to instantly make your email polished, polite, and professional.</p>
          <a href="https://chrome.google.com/webstore/detail/niceremail/abncbkabpogmedlafpfdfafnfkafieke?hl=en&authuser=0"><button>Add Nicer.email to Gmail</button></a>
          <p></p>
          <div className="main-image">
            <img src="https://cldup.com/uhDB1E7i7m1/H6hNsw.gif" alt="Click the green smiley button to niceify your email." />
          </div>
          <p>Don't <em>write</em> nicer emails. Just <em>send</em> nicer emails.</p>
        </div>
        <footer>
          <a href="/privacy">Privacy</a> | <a href="/contact">Contact</a>
        </footer>
      </>
    </>
  );
}

export default IndexPageSimple;
