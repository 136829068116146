import React from "react";
import Fathom from "./../components/Fathom"

function PrivacyPage(props) {
  return (
    <>
      <head>
        <meta charSet="utf-8" />
        <meta name="description" content="Nicer.email - Privacy policy" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </head>
      <Fathom />
      <body>
        <h1>Privacy Policy</h1>
        <p>
          Your privacy is important to us. It’s our policy to respect your
          privacy and comply with any applicable law and regulation regarding
          the personal information we collect about you through this website,
          Nicer.email, our browser extension, and through any associated
          services and third-party platforms we may use.
        </p>
        <h2>Information we collect</h2>
        <p>We do not collect any personal information about you.</p>
        <h3>Information You Provide to Us</h3>
        <p>
          You provide information as part of email messages when you use
          Nicer.email while composing an email or email reply.
        </p>
        <h3>Automatically collected information</h3>
        <p>
          Our servers automatically log standard data provided by your web
          browser when you visit our website. This data may include your
          device’s IP address, device type, operating system, browser type and
          version, the pages you visit, the time and date of your visit, time
          spent on each page, and other details about your visit or device.
        </p>
        <h2>How We Use Your Information</h2>
        <p>
          We use information that you provide to us to provide you with the
          functionality of the Nicer.email browser extension that you chose to
          use by installing our extension.
        </p>
        <p>
          We use information that is automatically collected to understand the
          size and composition of our audience and market.
        </p>
      </body>
    </>
  );
}
export default PrivacyPage;
